"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOGIN = void 0;
var client_1 = require("@apollo/client");
exports.LOGIN = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation Login($username: String!, $password: String!, $role: ID) {\n    login(input: { username: $username, password: $password, role: $role }) {\n      user {\n        id\n        name\n        password\n        token\n        roles {\n          id\n          name\n        }\n        user_info {\n          userid\n          first_name\n          last_name\n          gender\n          address_country\n          address_state\n          address_zone\n          address_zone1\n          address_city\n          address_locality\n          user_group\n        }\n        accounts {\n          account_id\n          bank_account {\n            bank_id\n            bank {\n              bank_name\n              bank_code\n            }\n          }\n        }\n      }\n    }\n  }\n"], ["\n  mutation Login($username: String!, $password: String!, $role: ID) {\n    login(input: { username: $username, password: $password, role: $role }) {\n      user {\n        id\n        name\n        password\n        token\n        roles {\n          id\n          name\n        }\n        user_info {\n          userid\n          first_name\n          last_name\n          gender\n          address_country\n          address_state\n          address_zone\n          address_zone1\n          address_city\n          address_locality\n          user_group\n        }\n        accounts {\n          account_id\n          bank_account {\n            bank_id\n            bank {\n              bank_name\n              bank_code\n            }\n          }\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
