"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportType = void 0;
exports.reportType = [
    {
        id: 1,
        value: 1,
        label: "Full report",
        name: "full",
        isDisplay: false,
    },
    {
        id: 2,
        value: 2,
        label: "Composition of household",
        name: "household",
        isDisplay: true,
    },
    {
        id: 3,
        value: 3,
        label: "Financial Situation",
        name: "financial_situatio",
        isDisplay: true,
    },
];
