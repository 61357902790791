"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DISEASES = void 0;
var client_1 = require("@apollo/client");
var GET_DISEASES = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getDiseasesList($organization_id: ID!) {\n    getDiseasesList(organization_id: $organization_id) {\n      id\n      name\n      organization_id\n      image_web\n      image_app\n      sorting\n      scientific_name\n      published\n      description\n    }\n  }\n"], ["\n  query getDiseasesList($organization_id: ID!) {\n    getDiseasesList(organization_id: $organization_id) {\n      id\n      name\n      organization_id\n      image_web\n      image_app\n      sorting\n      scientific_name\n      published\n      description\n    }\n  }\n"])));
exports.GET_DISEASES = GET_DISEASES;
var templateObject_1;
