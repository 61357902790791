"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_LANGUAGE_WITH_TRANSLATION = exports.GET_LANGUAGES = void 0;
var client_1 = require("@apollo/client");
var GET_LANGUAGE_WITH_TRANSLATION = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getLanguageWithTranslation(\n    $country_code: String!\n    $language_code: String\n  ) {\n    getLanguageWithTranslation(\n      country_code: $country_code\n      language_code: $language_code\n    ) {\n      label\n      code\n      translation\n    }\n  }\n"], ["\n  query getLanguageWithTranslation(\n    $country_code: String!\n    $language_code: String\n  ) {\n    getLanguageWithTranslation(\n      country_code: $country_code\n      language_code: $language_code\n    ) {\n      label\n      code\n      translation\n    }\n  }\n"])));
exports.GET_LANGUAGE_WITH_TRANSLATION = GET_LANGUAGE_WITH_TRANSLATION;
var GET_LANGUAGES = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getLanguage($country_code: Mixed!) {\n    getLanguage(\n      country_code: { column: CODE, operator: EQ, value: $country_code }\n    ) {\n      id\n      code\n      name\n    }\n  }\n"], ["\n  query getLanguage($country_code: Mixed!) {\n    getLanguage(\n      country_code: { column: CODE, operator: EQ, value: $country_code }\n    ) {\n      id\n      code\n      name\n    }\n  }\n"])));
exports.GET_LANGUAGES = GET_LANGUAGES;
var templateObject_1, templateObject_2;
