"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_DISEASE = exports.DELETE_DISEASE = void 0;
var client_1 = require("@apollo/client");
var DELETE_DISEASE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation deleteDisease($id: ID!, $organization_id: ID) {\n      deleteDisease(id: $id, organization_id: $organization_id)\n    }\n  "], ["\n    mutation deleteDisease($id: ID!, $organization_id: ID) {\n      deleteDisease(id: $id, organization_id: $organization_id)\n    }\n  "]))), UPSERT_DISEASE = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation upsertDisease(\n      $id: ID\n      $name: String!\n      $scientific_name: String\n      $published: Boolean\n      $sorting_order: Int\n      $disease_type_id: ID\n      $organization_id: ID!\n      $icon: Upload\n    ) {\n      upsertDisease(\n        id: $id\n        name: $name\n        scientific_name: $scientific_name\n        sorting_order: $sorting_order\n        disease_type_id: $disease_type_id\n        published: $published\n        organization_id: $organization_id\n        icon: $icon\n      )\n    }\n  "], ["\n    mutation upsertDisease(\n      $id: ID\n      $name: String!\n      $scientific_name: String\n      $published: Boolean\n      $sorting_order: Int\n      $disease_type_id: ID\n      $organization_id: ID!\n      $icon: Upload\n    ) {\n      upsertDisease(\n        id: $id\n        name: $name\n        scientific_name: $scientific_name\n        sorting_order: $sorting_order\n        disease_type_id: $disease_type_id\n        published: $published\n        organization_id: $organization_id\n        icon: $icon\n      )\n    }\n  "])));
exports.DELETE_DISEASE = DELETE_DISEASE;
exports.UPSERT_DISEASE = UPSERT_DISEASE;
var templateObject_1, templateObject_2;
