"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperAdminRoutes = exports.AdminRoutes = exports.ClientRoutes = exports.NonAuthRoutes = exports.AuthRoutes = void 0;
var AuthRoutes;
(function (AuthRoutes) {
    // dashboard = "/dashboard",
    AuthRoutes["organization"] = "/organization";
})(AuthRoutes = exports.AuthRoutes || (exports.AuthRoutes = {}));
var NonAuthRoutes;
(function (NonAuthRoutes) {
    NonAuthRoutes["login"] = "/";
    NonAuthRoutes["unauthorized"] = "/unauthorized";
})(NonAuthRoutes = exports.NonAuthRoutes || (exports.NonAuthRoutes = {}));
var ClientRoutes;
(function (ClientRoutes) {
    ClientRoutes["dashboard"] = "/agent/dashboard";
    ClientRoutes["usage"] = "/agent/usage";
    ClientRoutes["education"] = "/agent/education";
    ClientRoutes["myFinance"] = "/agent/myFinance";
    ClientRoutes["service"] = "/agent/service";
    ClientRoutes["underConstruction"] = "/agent/underConstruction";
})(ClientRoutes = exports.ClientRoutes || (exports.ClientRoutes = {}));
var AdminRoutes;
(function (AdminRoutes) {
    AdminRoutes["organization"] = "/admin/organization";
    AdminRoutes["dashboard"] = "/admin/dashboard";
    AdminRoutes["underConstruction"] = "/admin/underConstruction";
    AdminRoutes["usage"] = "/admin/usage";
    AdminRoutes["education"] = "/admin/education";
    AdminRoutes["knowledge"] = "/admin/knowledge";
    AdminRoutes["disease"] = "/admin/disease";
    AdminRoutes["disease_video"] = "/admin/disease/vidoe";
    AdminRoutes["disease_question"] = "/admin/disease/question";
    // education_video = "/admin/education/vidoes",
    // education_question = "/admin/education/questions",
    AdminRoutes["myFinance"] = "/admin/myFinance";
    AdminRoutes["tabularOption"] = "/admin/tabularOption";
    AdminRoutes["service"] = "/admin/service";
    AdminRoutes["serviceMain"] = "/admin/service_center";
    AdminRoutes["report"] = "/admin/report";
    AdminRoutes["generalReport"] = "/admin/General";
    AdminRoutes["guide"] = "/admin/guide";
})(AdminRoutes = exports.AdminRoutes || (exports.AdminRoutes = {}));
var SuperAdminRoutes;
(function (SuperAdminRoutes) {
    SuperAdminRoutes["dashboard"] = "/superadmin/dashboard";
})(SuperAdminRoutes = exports.SuperAdminRoutes || (exports.SuperAdminRoutes = {}));
